.full-team-shot{
    display: block;
    position: relative;
    height: 60vh;
    overflow: hidden;
    background-image: url('/static/images/full-team-shot/TeamShot_Mobile-min.jpg');
    color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: sticky;
    bottom: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.1s linear 3s;
    
    .consent-set & {
        opacity: 1;
    }

    .content-container {
        position: absolute;
        bottom: 5%;
        right: 5%;
        transition: all 0.2s linear;
        transform: translateY(150%);
        max-width: 80%;
    }

    @media screen and(min-width: 768px){
        height: 50vh;
        background-image: url('/static/images/full-team-shot/TeamShot_Tablet-min.jpg');
    }

    @media screen and(min-width: 1025px){
        height: 80vh;
        background-image: url('/static/images/full-team-shot/TeamShot_Desktop-min.jpg');
    }  
}