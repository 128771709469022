.tech-tips {
    // padding-top: 8em;
    &.bg-pink {
        padding-top: 0;

        .spacer {
            height: 16px;
            background: getColor(bt-indigo);
            margin-bottom: 3.75em;
        }

        .icon-play-pink {
            background-image: url("/static/images/icon_play_indigo.png");
        }
    }
    
    .max-width-container {
        .step-grid-wrapper {
            position: relative;

            .step {
                width: 258px;
                margin: 0 auto;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }
                .card > .desc{
                    padding: 0.75em 1em;
                }

                .card:not(.upcoming):hover {
                    div.desc{
                        background: #ffffff;
                    }
                    div.desc h3{
                        color: #5514B4;
                    }
                    div.desc p{
                        color: #1b1b1b;
                    }
                    .cta-purple{
                        background-color: #ffffff;
                        color: #5514b4;
                        border-color:#5514b4;
                    }
                }
            }

            @media screen and(min-width: 768px) {
                display: flex;
                flex-wrap: wrap;

                .step {
                    width: 50%;
                    margin: 0 !important;
                }
                .step:nth-child(even) {
                    padding-left: 21px;
                    transform: translateY(20%);

                    &.is-upcoming {
                        transform: translateY(65%);
                    }
                }

                .step:nth-child(odd) {
                    padding-right: 21px;
                }

                .step:nth-child(1),
                .step:nth-child(2) {
                    padding-bottom: 38px;
                }
                .step:nth-child(4) {
                    padding-top: 6px;
                }
            }
        }

        .cta-wrapper {
            display: none;

            @media screen and(max-width: 767px) {
                &.mobile-only {
                    margin-top: 46px;
                    display: block
                }
            }

            @media screen and(min-width: 768px) {
                &.desktop-only {
                    display: block
                }
            }
            .cta-yellow {
                background-color: #5514b4;
                color: #e1f935;
                border: 2px solid #e1f935;
                font-weight: 700;
                &:hover{
                    background-color: #e1f935;
                color: #5514b4;
                border-color: #e1f935;
                }
            }
        }
    }
    .img-wrapper {
        overflow: hidden;
        border-radius: 20px 20px 0 0;
        max-height: 160px;

        img {
            border-radius: 20px 20px 20px 20px;
            min-height: 160px;
            object-fit: cover;
            width: 100%;
        }
    }
    .desc{
        p{
            margin-bottom: 10px;
        }
        .cta-purple{
            background-color: #5514b4;
            color: #FFF;
            border: 2px solid #5514b4;
            font-weight: 700;
            font-size: 14px;
                &.md-auto-right{
                    margin-right:auto;
                    // @media screen and(min-width: 992px) {
                    //     margin-right: auto;
                    // }
                    // @media screen and(min-width: 1024px) {
                    //     margin: 0;
                    // }
                }
            &:hover{
                background-color: #ffffff;
                color: #5514b4;
                border-color:#5514b4;
            }
        }
        .cta-video{
            padding-left: 38px;
            @media screen and(max-width: 768px) {
                padding-left: 35px;
                font-size: 12px;
            }
            @media screen and(max-width: 767px) {
                padding-left: 38px;
                font-size: 14px;
            }
            &:before{
                content: " ";
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                left: 3px;
                background: url(/static/images/play-icon-yellow.svg) no-repeat;
                background-size: cover;
            }
        }
    }

    @media screen and(min-width: 768px) {
        .max-width-container {
            display: flex;

            & > div {
                display: flex;
                flex: 1;
            }

            .heading-small > * {
                max-width: 100%;
            }
        }
    }
}
