.error{
    nav {
        position: relative;
        z-index: 2;
    }
    .home {
        margin-top: 8px;
        img {
            max-width: 48px;
        }
    }
    .home-united-logo-black {
        display: block;
        top: 20px;
    }
    .view-two-column{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;    
        .c-grid-row {
            @media screen and (min-width: 768px) {
                display: flex;
                align-items: center;
            }
            [class*="c-col-"] {
                float: left;
                padding: 16px;
            }
            .left-container {
                position: relative;
                @media screen and (min-width: 768px){
                    position: static;
                }
                .image-container {
                    background: url(/static/images/404_Mobile.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top center;
                    height: 483px;
                    @media screen and (min-width: 768px){
                        background: url(/static/images/404_Tablet_PORT.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        height: 100vh;
                    }
                    @media screen and(min-width: 992px){
                        background: url(/static/images/404_Desktop.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: top center;
                        height: 100vh;
                    }
                    @media screen and (min-width: 1024px){
                        background: url(/static/images/404_Tablet_PORT.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        height: 100vh;
                    }
                    @media screen and (min-width: 1440px){
                        background: url(/static/images/404_Desktop.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: top center;
                        height: 100vh;
                    }
                }
            }
            .c-col-sm {
                width: 100%;
                padding: 0;
                @media screen and (min-width: 768px){
                    width: 50%;
                }
                &.right-container{
                    padding-left: 32px;
                    padding-right: 30px;
                    @media screen and (min-width: 768px){
                        padding-left: 70px;
                    }
                    @media screen and (min-width: 992px){
                        padding-left: 90px;
                    }
                    @media screen and (min-width: 1336px){
                        padding-left: 120px;
                    }
                }
                .detail{
                    margin-top: 30px;
                    margin-bottom: 52px;
                    @media screen and (min-width: 768px){
                        margin: 0;
                    }
                    p{
                        font-size: 18px;
                        line-height: 34px;
                        margin-bottom: 48px;
                        width: 100%;
                        @media screen and (min-width: 768px){
                            width: 400px;
                            font-size: 18px;
                        }
                        @media screen and (min-width: 992px){
                            width: 419px;
                            font-size: 22px;
                            line-height: 50px;
                        }
                    }
                    .cta{
                        display: block;
                        line-height: 60px;
                        text-align: center;
                        @media screen and (min-width: 768px){
                            display: inline-block;
                            line-height: 43px;
                        }
                    }
                }
            }
        }
    }
    footer {
        display: none;
    }
}