//Sponsor css
.key-sponsors {
    .primary-heading {
        font-size: 3.125em;
        @media screen and (min-width: 992px) {
            font-size: 6.25em;
            margin-bottom: 30px;
        }
    }
}

.sponsors-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media screen and(min-width:768px){
        justify-content: space-between;
    }

    @media screen and(min-width: 992px){
        flex-wrap: nowrap;
    }

    .sponsor-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (min-width: 992px) {
            max-width: 360px;
        }
        .sponsor-icon {
            min-height: 0;
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 30px;
            img {
                max-width: 100%;
            }
        }
        p {
            text-align: center;
            padding-left: 1em;
            padding-right: 1em;
            font-weight: 600;
            line-height: 1.7;
            margin: 0;
            max-width: 100%;
        }
        .cta {
            text-align: center;
            white-space: nowrap;
        }

        @media screen and (min-width: 768px) {
            width: 50%;
            margin-bottom: 0;

            &:first-child {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        @media screen and (min-width: 992px) {
            width: 33.33%;
        }
    }

    .sponsor-header{
      align-items: flex-start;

      h2, p{
        text-align: left;
        padding: 0;
      }
      
      p{
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        
        @media screen and(min-width: 767px) {
            font-size: 22px;
            line-height: 30px;
        }  
      }
    }
}
