html {
    scroll-behavior: smooth;
  }
.introduction-section{
    background: #5514b4;
    text-align: center;
    padding-top: 6.75em;
    padding-bottom: 0;
    @media screen and (min-width: 768px) {
        padding-top: 5.75em;
    }
    .content-wrapper{
        display: inline-block;
        border-bottom: 2px dashed #E1F935;
        margin-right: 30px;
        margin-left: 30px;
        @media screen and (min-width: 768px) {
            margin-right: 0px;
            margin-left: 0px;
        }
        p{
            font-size: 18px;
            line-height: 21px;
        }
        > div{
            padding-bottom: 32px;
            
            &.d-flex {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                @media screen and (min-width: 768px) {
                    display: flex;
                    flex-direction: row;
                } 
            }
            
            &.justify-content-center {
                    justify-content: center;
            } 
                   
            .intro-block {
                margin-right: 0px;
                width: 100%;
                text-align: left;
                padding-left: 68px;
                display: inline-block;
                position: relative;
                &:last-child{
                    margin-right: 0px;
                }
               
                @media screen and (min-width: 768px) {
                    margin-right: 65px;
                    width: 200px;
                    text-align: center;
                    padding: 0;
                    position: static;
                }
               
                a{
                    text-decoration: none;
                    h2 {
                        font-size: 50px;
                        line-height: 45px;
                        color: #E1F935;
                        margin-top: 18px;
                        @media screen and (min-width: 768px) {
                            margin-top: 0.605em;
                            font-size: 80px;
                            line-height: 64px;
                        } 
                    }
                    p{
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 4px;
                        margin-bottom: 4px;
                        color: #fff;
                        @media screen and (min-width: 768px) {
                            font-size: 18px;
                            line-height: 21px;
                            margin-top: 7px;
                            margin-bottom: 10px;
                        } 
                    }
                    .link-to {
                        background-image: url(/static/images/link_default.svg);
                        -webkit-transition: all .3s ease-in-out;
                        width: 40px;
                        height: 40px;
                        display: inline-block;
                        position: absolute;
                            top: 18%;
                            left: 0;
                        @media screen and (min-width: 768px) {
                            position: static;
                        } 
                    }
                    &:hover .link-to {
                        background-image: url(/static/images/link_hover.svg);
                    }
                }
               
            }
        }
    }
}