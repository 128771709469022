$colorPalette: (
	// Brand colours
	bt-pink: #FF80FF,
	bt-indigo: #5514B4,
	bt-dark-indigo: #4405A0,
	bt-black: #1B1B1B,
	bt-magenta: #E60051,
	bt-blue: #1AA0FB,
	hope-magenta: #F923C3,
	hope-cyan: #2AE6FD,
	hope-coral: #FF4250,
	hope-yellow: #E1F935,
	hope-green: #61DE67,

	black: #000000,
	white: #ffffff
);

@function getColor($color) {
    @return map-get($colorPalette, $color);
}

@each $colorName, $color in $colorPalette {
	.bg--#{$colorName} {
		background-color: $color !important;
	}

	.border--#{$colorName} {
		border-color: $color !important;
	}

	.color--#{$colorName} {
		color: $color !important;
	}
}
