#section-5, #the-first-team {
    overflow: hidden;
    h2 {
        color: #FF80FF;
    }
}

.carousel-card {
    position: relative;
    width: 100%;
    overflow: hidden;

    .icon {
        top: 1.5em;
        right: 1.2em;
    }

    >img,
    >.img-wrapper img {
        min-height: 460px;
        @media screen and (min-width: 768px) {
            min-height: 620px;
        }
    }
    .text-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        font-family: "TIMMONSNY-Regular";
        transform-origin: 0 0;
        transform: rotate(270deg);
        .number {
            font-size: 150px;
            line-height: 153px;
            transform-origin: 0 0;
            transform: rotate(-270deg);
            position: absolute;
            left: 0;
            top: 10px;
        }
        .name {
            font-size: 85px;
            line-height: 86px;
            transform-origin: 0 0;
            // position: absolute;
            // left: -45px;
            // top: -120px;
        }

        .label {
            font-size: 92px;
            line-height: 107px;
            transform-origin: 0 0;
            transform: rotate(-270deg);
            position: absolute;
            left: 188px;
            top: 10px;
        }
    }
    .swiper-slide:nth-child(even) & {
        .text-wrapper {
            right: -70px;
            bottom: 80px;
        }
    }

    .swiper-slide:nth-child(odd) & {
        .text-wrapper {
            bottom: 50%;
            left: 25px;
        }
    }

    .swiper-slide:nth-child(1) & {
        .text-wrapper {
            left: 70% !important;
            bottom: -50px;
        }
    }

    .swiper-slide:nth-child(4) & {
        .text-wrapper {
            right: -15% !important;
        }
    }

    .swiper-slide:nth-child(6) & {
        .text-wrapper {
            right: -3% !important;
        }
    }

    @media screen and (max-width: 767px) {
      .swiper-slide:nth-child(7) & {
          .text-wrapper {
              bottom: 40% !important;
          }
      }
      .swiper-slide:nth-child(19) & {
        .text-wrapper {
            bottom: 40% !important;
        }
      }
    }

    .swiper-slide:nth-child(8) & {
        .text-wrapper {
            right: 10% !important;
        }
    }

    .swiper-slide:nth-child(10) & {
        .text-wrapper {
            right: 5% !important;
        }
    }

    .swiper-slide:nth-child(12) & {
        .text-wrapper {
            right: 4% !important;
        }
    }

    .swiper-slide:nth-child(14) & {
        .text-wrapper {
            right: -56% !important;
        }
    }

    .swiper-slide:nth-child(16) & {
        .text-wrapper {
            right: -2% !important;
        }
    }

    .swiper-slide:nth-child(18) & {
        .text-wrapper {
            right: 2% !important;
        }
    }

}

.bt-carousel {
    margin-top: 40px;
    &-controls {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        line-height: 24px;
        margin-top: 50px;
        @media screen and (min-width: 992px) {
            margin-right: 25%;
            margin-left: 25%;
        }
    }
    .swiper-wrapper {
        flex-wrap: nowrap;
    }
    .swiper-slide {
        height: auto;
        transition: transform 0.4s linear, opacity 0.6s linear 0.2s;
        transform: translateY(100%);
        opacity: 0;
        &:nth-child(2) {
            transition: transform 0.4s linear 0.2s, opacity 0.6s linear 0.4s;
        }
        &:nth-child(3) {
            transition: transform 0.4s linear 0.25s, opacity 0.6s linear 0.45s;
        }
        &:nth-child(4) {
            transition: transform 0.4s linear 0.3s, opacity 0.6s linear 0.5s;
        }
    }
    &.apply-animation {
        .swiper-slide  {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .swiper-pagination {
        width: 80%;
        height: 2px;
        border-radius: 4px;
        position: relative;
        overflow: visible;
        background: #4d4d4d;
        display: flex;
        margin: 0 auto;
        z-index: 1;
        @media screen and (min-width: 768px) {
            width: 60%;
        }
        @media screen and (min-width: 1200px) {
            width: 65%;
        }
        &-bullet {
            flex: 1;
            height: auto;
            width: auto;
            border-radius: 4px;
            background: transparent;
            &-active {
                background: #E1F935;
                height: 6px;
                margin-top: -2px;
            }
        }
    }

    .swiper-button-prev.swiper-button-disabled {
      background-image: url('/static/images/left-arrow-inactive.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 40px;
      width: 40px;
      pointer-events: none;
    }

    .swiper-button-next.swiper-button-disabled {
      background-image: url('/static/images/right-arrow-inactive.svg');
      background-repeat: no-repeat;
      background-position: center;
      height: 40px;
      width: 40px;
      pointer-events: none;
    }

    .swiper-button {
        &-next,
        &-prev {
            display: none;
            @media screen and (min-width: 768px) {
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center;
                height: 40px;
                width: 40px;
            }
        }

        @media screen and (min-width: 768px) {
          &-next {
            background-image: url('/static/images/right-arrow-default.svg');
              &:hover{
                background-image: url('/static/images/swipe-right-hover.svg');
              }
          }
          &-prev {
            background-image: url('/static/images/left-arrow-default.svg');
              &:hover{
                background-image: url('/static/images/swipe-left-hover.svg');
              }
          }
        }
    }
}
