/*
@font-face {
  font-family: "Btcurvecd w bd";
  src: url("/static/fonts/BTCurveCd_W_Bd.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Btcurve w headline";
  src: url("/static/fonts/BTCurve_W_Headline.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
*/
@font-face {
    font-family: "Btcurve w rg";
    src: url("/static/fonts/BTCurve_W_Rg.woff2") format("woff2"), url("/static/fonts/BTCurve_W_Rg.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Btcurve w bd";
    src: url("/static/fonts/BTCurve_W_Bd.woff2") format("woff2"), url("/static/fonts/BTCurve_W_Bd.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "TIMMONSNY-Regular";
    src: url("/static/fonts/TIMMONSNY2.0-Regular.woff2") format("woff2"), url("/static/fonts/TIMMONSNY2.0-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
}
body {
    background-color: #5514b4;
    font-family: "Btcurve w rg", sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    min-height: 100%;
}

a {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
}

img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}
.max-width-container {
    margin-right: 30px;
    margin-left: 30px;
    padding: 0;
}
.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
}
.sr-only-t {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;

    width: 1px !important;
    white-space: nowrap !important;
    /* 3 */
}

.gradient-default {
    background: #5514b4;
    color: #fff;
    transition: all linear 0.4s;
}
.uppercase {
    text-transform: uppercase;
}
.heading-small {
    color: #fff;
    line-height: 1.5em;
    align-items: flex-start;
    flex-direction: column;
    >* {
        margin-right: 0%;
    }
    .bt-tech-logo{
      font-size: 1.25em
    }
    img {
        margin-right: 0.5em;
    }
    span {
        color: #ff80ff;
    }
    a:not(.cta) {
        font-size: 1em;
        &:hover {
            font-size: 1.05em;
        }
    }
    p {
        font-size: 1.12em;
        line-height: 1.5;
        margin-bottom: 3em;
    }
}

.primary-heading {
    font-family: "TIMMONSNY-Regular";
    font-size: 4.375em;
    /*70px*/
    font-weight: normal;
    line-height: 0.8;
    /*56px*/
    margin: 0.428em 0 0.030em;
}

a.card:hover,
a.card:focus,
a.card:active {
    text-decoration: none;
    outline: none;

    div.desc
    {
        background-color: #5514b4;

        h3, p{
          color: getColor(white);
        }
    }

    .img-wrapper {
        img {
            transform: scale(1.05);
        }
    }

    .icon-play {
        background: #5514b4;
        width: 80px;
        height: 80px;
        top: -10px;
        right: -10px;

        &::before {
            background-image: url("/static/images/icon_play_hover.png");
        }
    }

    .icon-expand {
        background: #5514b4;
        border: none;
        width: 104px;
        height: 104px;
        top: -11px;
        right: -16px;

        &::before {
            background-image: url("/static/images/icon_expand_hover.svg");
        }
    }
}


a.card.upcoming:hover,
a.card.upcoming:focus,
a.card.upcoming:active {
    text-decoration: none;
    outline: none;
    transform: none;
    cursor: default;

    div.desc
    {
        background-color: inherit;
        h3{
          color: getColor(bt-pink);
        }
        p{
          color: getColor(white);
        }
    }
}

.card {
    color: #1b1b1b;
    position: relative;
    text-decoration: none;
    outline: none;
    overflow: hidden;
    transition: transform 0.5s ease;
    width: 100%;
    
    &>.img-wrapper {
        overflow: hidden;
        border-radius: 30px;
    }

    &>.img-wrapper img {
        border-radius: 20px 20px 20px 20px;
        min-height: 275px;
        object-fit: cover;
        width: 100%;
        transition: all 0.5s linear;
    }
    &>img {
        border-radius: 20px 20px 20px 20px;
        min-height: 275px;
        object-fit: cover;
        width: 100%;
    }
}

.card>.desc {
    background: #ffffff;
    border-radius: 0 0 20px 20px;
    padding: 1em 1em 1.5em 1em;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    h3 {
        margin: 0;
        font-size: 16px;
        color: #5514B4;
        font-family: "Btcurve w bd";
    }
    img {
        border-radius: 20px 20px 20px 20px;
        min-height: 275px;
        object-fit: cover;
        width: 100%;
    }
}

.card>.desc p {
    margin: 0;
}

.card.has-desc>img {
    border-radius: 20px 20px 0 0;
}

.card.has-desc .img-wrapper img {
    border-radius: 20px 20px 0 0;
}

.card>.icon {
    width: 34px;
    height: 34px;
    position: absolute;
    right: 1em;
    top: 1em;
}

.card>.desc .icon {
    width: 24px;
    height: 24px;
    align-self: flex-end;
}

.icon {
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-play {
    background: white;
    border-radius: 50%;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &::before {
        content: " ";
        width: 13px;
        height: 14px;
        display: block;
        background-image: url("/static/images/icon_play.png");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.icon-play-pink {
    background-image: url("/static/images/icon_play_pink.png");
}

.icon-play-indigo {
    background-image: url("/static/images/icon_play_indigo.png");
}

.icon-new-window {
    background-image: url("/static/images/external-link.svg");
}

.icon-new-window-black {
    background-image: url("/static/images/icon_new_window_black.png");
}

.icon-externalLink {
    background-image: url("/static/images/external-link.svg");
}

.icon-expand {
    width: 40px;
    height: 40px;
    border: 1px solid #e1f935;
    border-radius: 50%;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &::before {
        content: " ";
        width: 18px;
        height: 18px;
        display: block;
        background-image: url("/static/images/icon_expand_hover.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

section {
    padding: 3em 0;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mt-0 {
    margin-top: 0;
}

.mt-1 {
    margin-top: 1em;
}

.mt-1-5 {
    margin-top: 1.5em;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-1 {
    margin-bottom: 1em;
}

.mb-1-5 {
    margin-bottom: 1.5em;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-1 {
    margin-top: 1em;
    margin-bottom: 1em;
}

.my-1-5 {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.my-2 {
    margin-top: 2em;
    margin-bottom: 2em;
}

.m-0{
  margin: 0;
}

.p-0 {
    padding: 0;
}

.pt-1-5 {
    padding-top: 1.5rem;
}

.pb-1-2 {
    padding-bottom: 1.2rem;
}

.color-white {
    color: #ffffff;
}

.color-yellow {
    color: #e1f935;
}

.color-pink {
    color: #ff80ff;
}

.color-cyan {
    color: #2ae6fd;
}

.color-black {
    color: #1b1b1b;
}

.text-purple {
    color: #5514b4;
}

.bg-black {
    background-color: #1b1b1b;
}

.bg-yellow {
    background: #e1f935;
}

.bg-purple {
    background: #5514b4;
}

.bg-pink {
    background: #ff80ff;
    color: #1b1b1b;
}

.bg-cyan {
    background: #2ae6fd;
    color: #1b1b1b;
}

.bg-white {
    background: #ffffff;
}

.font-italic {
    font-style: italic;
}

.cta {
    &.icon {
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 95% center;
        padding-right: 2em;
        &:focus,
        &:hover,
        &:active {
            padding-right: 2em;
        }
    }
    &.cta-inline {
        display: inline-flex;

        &:focus,
        &:hover,
        &:active {
            display: inline-flex;
        }
    }

    &.cta-video,
    &.cta-download {
        position: relative;
        padding-left: 60px;
        &:before {
            content: " ";
            display: block;
            width: 36px;
            height: 36px;
            position: absolute;
            left: 3px;
            background: url("/static/images/cta-video.png") no-repeat;
            background-size: cover;
        }

        &:focus,
        &:hover,
        &:active {
            padding-left: 60px;

            &:before {
                background: url("/static/images/cta-video-hover.png") no-repeat;
                background-size: cover;
            }
        }
    }

    &.cta-download {
        &:before {
            width: 40px;
            height: 40px;
            background: url("/static/images/cta-download.svg") no-repeat;
        }

        &:focus,
        &:hover,
        &:active {
            &:before {
                background: url("/static/images/cta-download-hover.svg") no-repeat;
            }
        }
    }
}

.cta,
.cta:focus,
.cta:hover,
.cta:active {
    border-radius: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:2.4em;
    /*48px*/
    font-size: 1.25em;
    line-height: 2;
    padding: 0 1.25em;
    text-decoration: none;
    background-color: transparent;
    -webkit-transition: all 280ms ease-in-out;
    transition: all 280ms ease-in-out;
    white-space: nowrap;
    /* iPhone [portrait + landscape] */
    @media screen and (max-width: 375px) {
        padding: 0 .525em;
        font-size: 15px;
        line-height: 42px;
    }
}

.cta-default {
    border: solid 1px #ff80ff;
    color: #ff80ff;
}

.cta-default:hover,
.cta-default:focus,
.cta-default:active {
    background-color: #ff80ff;
    color: #5514b4;
}

.cta-primary {
    background-color: #2ae6fd;
    color: #5514b4;
    &:hover,
    &:focus,
    &:active {
        background-color: #2ae6fd;
        color: #5514b4;
        transform: scale(1.05);
    }
}

.cta-inverse {
    font-family: "Btcurve w bd";
    border: solid 2px #ff80ff;
    color: #1b1b1b;
    &:hover,
    &:focus,
    &:active {
        border: solid 2px #ff80ff;
        color: #1b1b1b;
        transform: scale(1.05);
    }
}

.cta-secondary {
    border: solid 2px #5514b4;
    background-color: #ffffff;
    color: #5514b4;
    font-weight: 700;
    &:hover,
    &:focus,
    &:active {
        background-color: #5514b4;
        color: #ffffff;
    }
}

.cta-yellow {
    background-color: #e1f935;
    color: #5514b4;
    border: 2px solid #e1f935;
    font-weight: 700;
    &:hover,
    &:focus,
    &:active {
        background-color: #5514b4;
        color: #e1f935;

        &.no-outline {
            border-color: #5514b4;
        }
    }

    &.cta-video {
        &:hover,
        &:focus,
        &:active {
            background-color: #5514b4;
            border: 2px solid #5514b4;
        }
    }
}
.text-underline {
    text-decoration: underline;

}
.icon-twittter {
    background-image: url("/static/images/icon-twitter.svg");
    -webkit-transition: all .3s ease-in-out;
    &:hover{
        background-image: url("/static/images/Twitter–Hover–Pink.svg");
    }
}

.icon-instagram {
    background-image: url("/static/images/icon-instagram.svg");
    transition: all .3s ease-in-out;
    &:hover{
        background-image: url("/static/images/icon-insta-hover.svg");
    }
}

.icon-facebook {
    background-image: url("/static/images/icon-facebook.svg");
        transition: all .3s ease-in-out;
        &:hover{
            background-image: url("/static/images/FB–Hover–Pink.svg");
        }
}

.social-share-wrapper a {
    margin: 0 0.25em;
}

.bg-black-pattern {
    background-color: rgba(0, 0, 0, 0.75);
}

/**/
.feed-wrapper {
    .card-wrapper {
        margin-bottom: 2.5em;
    }
}
.feed-container{
  .cta-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    p{
      font-size: 18px;
      text-align: center;
    }
  }
  .primary-heading{
    margin-bottom: 30px;
  }
}
.card {
    &.is-split-card {
      &:focus,
      &:active {
        div.desc{
          background-color: white;
          p{
            color: inherit;
          }
        }
        >.img-wrapper img {
          transform: scale(1);
        }
      }

      &:hover, &:focus{
        div.desc{
          background-color: #E1F935 !important;
          span.icon-new-window{
            background-image: url("/static/images/external-link-hover.svg");
          }
          p{
            color: #000;
          }
        }

        >.img-wrapper img {
          transform: scale(1.05);
        }
      }
        padding-left: 20px;
        padding-right: 20px;

        &>.img-wrapper {
          overflow: hidden;
          border-radius: 20px;
          margin-bottom: -65%;
          z-index: 1;
          position: relative;
        }

        >img {
            border-radius: 20px;
        }
        >.img-wrapper img {
            border-radius: 20px;
        }
        >.desc {
            padding-top: 71.25%;
            padding-bottom: 1em;
            border-radius: 20px;
            margin-left: -20px;
            margin-right: -20px;
            p{
              font-size: 18px;
            }
            .source{
              margin-top: 25px;

              .source-name{
                font-size: 18px;
                color: getColor(bt-indigo);
              }

              .icon{
                float: right;
              }
            }
        }
    }

    &.upcoming {
        border: 2px dashed #FF80FF;
        border-radius: 10px;
        width: 100%;

        .desc {
            background: none;
            padding-top: 8px;
            padding-bottom: 8px;
            h3,
            p {
                font-family: "TIMMONSNY-Regular";
                font-weight: normal;
                color: white;
                font-size: 40px;
                line-height: 36px;
                margin: 0;
            }
            h3 {
                color: #FF80FF;
            }
        }

        #educate & {
            border-color: getColor(bt-indigo);
            
            .desc {
                h3 {
                    color: getColor(bt-indigo);
                }
            }

        }
    }
}

.next-match-wrapper {
    .schedule {
        margin: 0;
        padding: 0.5em 0;
        font-family: "Btcurve w bd";
        white-space: nowrap;
        span {
            font-family: "Btcurve w rg";
        }
    }
    .has-separator {
        border-top: dashed 2px #000000;
    }
}

.event-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 1em 0;
    .h2 {
        margin: 0 0.5em;
    }
}

.font-xl {
    font-family: "Btcurve w bd";
    font-size: 6.5em;
}

.marquee-block {
    position: relative;
    padding: 0;
    >div {
        overflow: hidden;
        margin: 0 auto;
        padding: 1em 0;
        max-width: 100%;
    }
    &.animate-forward {
        p {
            &:nth-child(even) {
                transform: translate3d(2%, 0, 0);
            }
            &:nth-child(odd) {
                transform: translate3d(-5%, 0, 0);
            }
        }
    }
    &.animate-reverse {
        p {
            &:nth-child(even) {
                transform: translate3d(-2%, 0, 0);
            }
            &:nth-child(odd) {
                transform: translate3d(5%, 0, 0);
            }
        }
    }
    .marquee-block:hover {
        -webkit-animation-play-state: paused;
        -moz-animation-play-state: paused;
        -o-animation-play-state: paused;
        animation-play-state: paused;
        cursor: pointer;
    }
    p {
        font-family: "TIMMONSNY-Regular";
        font-size: 6em;
        /*96px*/
        font-weight: normal;
        line-height: 0.8;
        /*56px*/
        letter-spacing: 0;
        margin: 0 auto;
        white-space: nowrap;
        text-align: center;
        left: 50%;
        margin-left: -65%;
        position: relative;
        transform: translate3d(0, 0, 0);
        transition: transform 2s linear;
        img {
            margin: 0 0.15em;
            max-width: 70px;
            vertical-align: initial;
        }
        &:nth-child(even) {
            margin-left: -300%;
        }
        &:nth-child(odd) {}
    }

    .font-italic {
        display: inline-block;
        margin-right: 15px;
    }
}

.flex-column {
    flex-direction: column;
}

.club-manifesto {
    .bg-pattern {
        background-image: url("/static/images/club-pattern-1.png");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .cta-default {
        padding: 0 1em;
    }
}

.gradient-secondary {
    background: linear-gradient(180deg, #30C8E5 0%, #F287FB 100%);
}

.social-section {
    padding-top: 10px;
    .primary-heading{
      a{
        text-decoration: none;
        color: getColor(bt-pink);
        &:hover, &:focus, &:active{
          text-decoration: none;
        }
      }
    }
    .d-flex {
        display: block;
    }
    .ml-auto {
        margin-left: 0;
        p {
            margin-right: 16px;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
        }
    }
    .icon {
        width: 48px;
        height: 48px;
    }
    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 32px;
        padding: 0 27px;
        .col {
            max-width: 100%;
            width: 100%;
            margin-bottom: 40px;
        }

        .twitter-card{
          margin-top: -10px !important;
        }

        .fb_iframe_widget {
            display: block;
        }
        .fb_iframe_widget span {
            width: 100% !important;
            border: 2px solid #fff;
            border-radius: 10px;
            overflow: hidden;
        }
        .fb_iframe_widget iframe {
            position: absolute;
            width: 100% !important;
            object-fit: contain !important;
            min-width: 100%;
            height: 100% !important;
        }
        .instagram-media {
            width: 100%;
            min-width: 100% !important;
            border-radius: 10px !important;
            border: 2px solid #fff !important;
        }
        .twitter-tweet.twitter-tweet-rendered>iframe {
            max-width: 100%;
        }
    }
}
.splash1,
.splash2,
.splash3 {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: none;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 1s;
    img {
        max-width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.d-none {
    display: none !important;
}
.club-manifesto{
  .primary-heading{
    margin-bottom: 35px;
  }
  .social-share-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 35px;
  }
  .icon{
    height: 48px;
    width: 48px;
  }
  p{
    margin-left: 12px;
  }
}

#rashfordMuralCTA {
    width: 100%;
    max-width: 330px;
}

.border-bottom-dotted {
    border-bottom: 2px dotted #000000;
}

@media screen and (max-width: 767px){
    .sm-block {
        display: block;
    }

    .sm-mb-2 {
        margin-bottom: 2em;
    }
}