
.overlay-two-column{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 11;

    .c-col-m-6{
        padding: 0;
    }

    .image-container {
        img {
            width: 100%;
        }
        @media screen and(min-width: 768px){
            img {
                opacity: 0;
            }
            
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
        }
    }

    .left-container, .right-container{
        position: relative;
    }

    .overlay-detail{
        max-width: 600px;
        margin: 0;
        padding: $spacing-xl;

        h2, h3{
            font-family: 'TIMMONSNY-Regular';
            font-weight: normal;
            color: getColor(bt-pink);
            margin: 0;
            text-transform: uppercase;
        }

        h3,
        h2{
            font-size: 70px;
            line-height: 56px;
        }

        span{
            font-family: 'Btcurve w bd';
        }

        p {
            font-size: 18px;
            line-height: 1.37;
        }
    }

    .overlay-image-main{
        width: 100%;
    }

    .error-callout {
        font-size: 20px;
        line-height: 24px;
        display: flex;

        .icon-alert {
            margin-right: 20px;
        }
    }

    @media screen and (min-width: 768px) {  
        .left-container, .right-container{
            position: static;
        }

        .overlay-detail{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100vh;
            padding-left: 120px;

            h2, h3 {
                line-height: 0.8;
            }

            h2{
                font-size: 200px;
            }

            h3{
                font-size: 140px;
            }

            p {
                font-size: 22px;
            }
        }

        .overlay-image-main{
            height: 100vh;
            width: auto;
        }
    }
}