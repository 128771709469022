@media screen and (min-width: 1200px) {
  .max-width-container {
    padding: 0;
  }
  .nav-title {
    color: orange;
  }
  .primary-heading {
    font-size: 6.25em; /*100px*/
  }
  .club-manifesto {
    .primary-heading {
      font-size: 9.375em;
      margin: 0.328em 0; //
    }
    .bg-pattern {
      img {
        width: 90%;
      }
    }
  }
  .marquee-block {
    p {
    }
  }
}
