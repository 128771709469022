footer {
    h2 {
        margin-bottom: 50px;
    }

    .footer-wrapper {
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
        padding-bottom: 32px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;

        @media screen and(min-width: 992px) {
            padding-top: 35px;
            padding-bottom: 35px;
            display: flex;
            font-size: 18px;
            line-height: 23px;
        }
        
        .footer-text {
            margin-right: 32px;
            font-weight: 700;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
        .footer-links {
            @media screen and(max-width: 992px) {
                margin-bottom: 40px;
            }
        }
        .footer-info {
            display: flex;
            align-items: center;
            .callout-text {
                max-width: 100px;
                font-weight: 500;
                line-height: 18px;
            }
            .copy-right {
                margin-right: 24px;
                @media screen and(min-width: 992px) {
                    margin-right: 32px;
                }
            }
            .footer-logos {
                display: flex;
                flex-grow: 1;
                flex-wrap: nowrap;
                align-items: center;
                >* {
                    margin-right: 11px;
                    @media screen and(min-width: 992px) {
                        margin-right: 13px;
                    }
                }

                .seperator {
                    width: 1px;
                    line-height: 48px;
                }
            }
        }
    }
}