.join-now {
    padding-top:0;
    padding-bottom: 1em;
    p>br {
        display: none;
    }

    .icon {
        width: 48px;
        height: 48px;
    }

    @media screen and(min-width: 768px) {
        padding: 0;
        > div > div {
            width: 50%;
        }

        p > br {
            display: block;
        }
    }

    .join-right {
        text-align: left;
        margin-top: 40px;

        .heading-text{
          border: none;
          border-bottom: 2px dotted #000;
        }

        .info-text{
            display: block;
            border: none;
            border-bottom: 2px dotted #000;
            
            h3{
              span.x-icon{
                font-size: 40px;
                vertical-align: top;
              }
            }

            p{
              padding: 0;
              padding-left: 0px;
              margin-top: 5px;
              margin-bottom: 16px;
              @media screen and(min-width: 768px) {
                padding-left: 12px;
              }
            }
        }

        a{
          margin: 0;
          margin-top: 40px;
        }

        .tncCTA{
          color: getColor(black);
          text-decoration: underline;
          margin-top: 30px;
          width: 100%;
          &:hover{
            text-decoration: underline;
          }
        }

        .cta-yellow{
          &:hover{
            border: 2px solid getColor(bt-indigo);
          }
        }

        @media screen and(min-width: 768px) {
            a {
                width: 50%;
            }
        }

        h2, h3{
          font-family: "TIMMONSNY-Regular";
          font-weight: normal;
        }

        h2 {
            font-size: 70px;
            line-height: 56px;
            margin: 0;
            margin-bottom:10px;
        }

        h3 {
            font-size: 48px;
            line-height: 60px;
            margin: 0;
        }

        h4 {
            color: #5514B4;
            font-size: 70px;
            line-height: .9;
            font-family: "TIMMONSNY-Regular";
            font-weight: 100;
            margin: 60px 0 10px;
        }
        p {
            font-size: 1.4em;
            line-height: 1.35;
        }

        sup{
          top:-0.3em;
        }
    }

    .join-left {
      position: relative;
      min-height: 300px;
        h2 {
            color: #000;
            font-size: 15em;
            line-height: .8;
            font-family: "TIMMONSNY-Regular";
            margin: 0;
            font-weight: 100;
        }

        .hope-shirt-img{
          max-width: 80%;
          height: auto;
          float: right;
          z-index: 2;
          position: absolute;
          right: 0;
          bottom: 0;
        }
    }

    hr{
      position: absolute;
      left: 0;
      border: none;
      border-bottom: 10px solid white;
      margin: 0;
      width: 100%;
      overflow: hidden;
    }

    @media screen and(min-width: 768px) and(max-width: 1024px)  {
      .join-right {
        padding-bottom: 1em;
        padding-left: 1em;
      }
      hr{
        display: none;
      }
    }
    @media screen and(min-width: 1025px) {
        margin-top: 0;

        .join-right {
          padding-left: 5em;
          h2 {
              font-size: 100px;
              line-height: 80px;
          }
          h3 {
              font-size: 60px;
              line-height: 80px;
              margin: 0;
          }

          .info-text{
              h3{
                span.x-icon{
                  font-size: 40px;
                }
              }
            }

          .tncCTA{
            margin-top: 55px;
          }
        }

        .join-left {
          .hope-shirt-img{
            max-width: 100%;
          }
        }

        hr{
          display: none;
        }
    }
}
