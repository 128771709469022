/// IE11 CSS override
// Usage
// .selector {
// 		font-family: Arial, Helvetica, sans-serif;//This renders in non IE11 browsers
// 		@include ie11() {
// 			font-family: $sans-serif;//This renders in IE11
// 		}
// }
@mixin ie11() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}