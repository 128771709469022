.overlay {
	position: fixed;
	top: 5%;
	left: 50%;
	padding: 0;
	background: getColor(bt-indigo);
	width: 150px;
  	height: 150px;
  	border-radius: 50%;
  	transform: translateX(-50%);
	display: none;
	z-index: 10;
	overflow-y: auto;

	&.show {
		// animation-name: showOverlay;
  		// animation-duration: 0.8s;
		// transform-style: preserve-3d;
		display: block;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 0;
	}

	//Uncomment once animation in place
	// > * {
	// 	visibility: hidden;
	// }

	// &.rendered > * {
	// 	visibility: visible;
	// }


	@keyframes showOverlay {
		0%   {width: 150px;height: 150px; top: 0;border-radius: 50%;}
		100% {width: 120%;height: 200%; top: -50%;border-radius: 50%;}
	}

	.home-united-logo {
		position: fixed;
		top:0;
	}


	.btn-close {
		float: right;
		margin: 0;
		padding: 0;
		background-image: url('/static/images/overlay/close-default.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-color: transparent;
		border: none;
		/*color: getColor(bt-pink);
		font-size: 24px;
		border-radius: 50%;
		border: 1px solid getColor(bt-pink);*/
		height: 48px;
		width: 48px;

		/*&:after {
			content: "X";
		}*/

		&:focus,
		&:hover {
			background-image: url('/static/images/overlay/close-hover.svg');
			/*background-color: getColor(bt-pink);
			color: getColor(bt-indigo);
			border-color: getColor(bt-pink);
			cursor: pointer;
			outline: none;*/
		}
	}

	.overlay-nav{
		display: flex;
		flex-direction: row;
		padding: $spacing-xs;
		z-index: 12;
		position: relative;

		.overlay-bt-logo{
			flex-grow: 1;

			&:focus,
			&:hover,
			&:active {
				outline: auto;
				flex: none;
				height: 48px;
			}
		}
		.btn-close-wrapper{
			flex-grow: 1;
		}
		.overlay-hu-logo-white{
			flex-grow: 1;
			text-align: center;
			img{
				height: auto;
				width: 72px;
			}
		}
	}

	.overlay-hero-video{
		.video {
			width: 100%;
		}

		.video-iframe-wrapper {
			position: relative;
			width: 100%;
			padding-bottom: 56.57%;
			height: 0;

			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.video-transcript{
			justify-content: flex-end;
			display: flex;
			p{
				background-color: getColor(bt-dark-indigo);
				border-radius: 25px;
				padding: 5px;
				margin: 0;
				a {
					color: getColor(white);
					text-decoration: none;
					font-size: 14px;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
		.hero-video-content{
			h2{
				line-height: 30px;
				font-size: 22px;
				margin-bottom: 24px;
			}
			p{
			}
		}
		.social-share-wrapper{
			margin-top: 40px;
			p{
				font-weight: bold;
				// text-align: center;
			}
			a{
				margin: 0;
				margin-right: 10px;
			}
		}
	}

	.signup-form{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.form-submission-error {
			padding-top: 30px;
			display: flex;
			align-items: center;
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;

			.icon-alert {
				margin-right: 10px;
			}

			@media screen and(min-width: 767px){
				padding-top: 40px;
			}

			&:not(.d-none) {
				+ .signup-form-wrapper {
					padding-top: 30px;

					@media screen and(min-width: 767px){
						padding-top: 43px;
					}
				}
			}
		}

		.signup-form-wrapper{
			@include ie11() {

				@media screen 
				and (min-device-width: 1200px) 
				and (max-device-width: 1600px) 
				and (-webkit-min-device-pixel-ratio: 1) { 
					width: 1200px;
				}

				@media screen and(min-width: 992px) {
					width: 992px;
				}

				@media screen and(min-width: 768) {
					width: 768;
				}
				
			}

			>p{
				font-weight: bold;
				text-align: center;
				margin: 0;
				font-size: 22px;
				line-height: 30px;
			}
			.form-layout{
				display: block;
				flex-wrap: wrap;
				margin-top: 66px;
				>div{
					flex: 1;
					// &:first-child{
					// 	padding-right: 100px;
					// }
					p{
						margin: 0;
						margin-bottom: 22px;
						font-weight: bold;
						font-size: 18px;
						line-height: 21px;
						&:first-child{
							display: flex;
							align-items: center;
						}
						.icon-alert:after {
							content: url(/static/images/alert-Icon.png);
							margin-right: 17px;
						}
					}
					.cta{
						padding: 0 3.25em;
						cursor: pointer;

					&:disabled{
						background-color: transparent;
						color: #fff;
						opacity: 0.5;
						border: 1px solid #FFFFFF;
						cursor: default;
					  }
					}
				}

				.checkbox-container {
					display: flex;
					align-items: center;
					position: relative;
					padding-left: 45px;
					margin-bottom: 49px;
					padding-top: 13px;
					cursor: pointer;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					border-top: 1px dashed;
					font-size: 18px;
					line-height: 22px;

					&.border-bottom-dashed{
						border-bottom: 1px dashed #fff;
						padding-bottom: 49px;
						margin-bottom: 13px;
					}

					&:hover input ~ .checkmark {
						background-color: #ccc;
					  }
				  input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					height: 30px;
					width: 30px;
					left: 0;
					@include ie11() {
						top: 17px;
					}

					&:checked ~ .checkmark {
						background-color: #E1F935;
					}

					&.required ~ .checkmark {
						border: 2px solid #AB023E;
					}
				  }

				  .checkmark {
					position: absolute;
					left: 0;
					height: 30px;
					width: 30px;
					background-color: #fff;
					@include ie11() {
						top: 17px;
					}
				  }
				}

				  .checkmark:after {
					content: "";
					position: absolute;
					display: none;
				  }
				  .checkbox-container input:checked ~ .checkmark:after {
					display: block;
				  }
				  .checkbox-container .checkmark:after {
					    left: 11px;
						top: 10px;
						width: 5px;
						height: 10px;
						border: solid #5514B4;
						border-width: 0 2px 2px 0;
						transform: rotate(45deg);
				  }
				.form-footer{
					flex: 0 0 100%;
					align-items: center;
					justify-content: center;
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					margin: 0 auto;
					margin-top: 60px;
					@include ie11() {
						display: block;
					}

					a {
						color: getColor(white);
						text-decoration: none;
						font-size: 18px;
						line-height: 21px;
						font-weight: bold;

						&:hover,
						&:focus,
						&:active {
							text-decoration: underline;
						}
					}
				}
				.floating-label {
					position: relative;
					margin-bottom: 12px;
					height: 50px;
					background: #fff;
					color: #4D4D4D;
					&:last-child .floating-input.invalid{
						margin-top: 50px;
						@media screen and(min-width: 767px) {
							margin-top: 42px;
						}
						@media screen and(min-width: 992px) {
							margin-top: 48px;
						}
						& ~ .highlight{
							top: -38px;
							line-height: 16px;
							@media screen and(min-width: 767px) {
								top: -34px;

							}
							@media screen and(min-width: 992px) {
								top: -34px;

							}
						}
					}
				  }
				  .floating-input {
					font-size: 18px;
					padding: 15px 7px 2px;
					display: block;
					width: 100%;
					height: 50px;
					background-color: transparent;
					border: 3px solid transparent;
					color: #011831;
					&.invalid{
						border-color: #AB023E !important;
						margin-top: 35px;
					  }

				  }
				  .floating-input:focus, .floating-input:hover  {
					   outline:none;
					   border: 3px solid #E1F935;
				  }

				  .floating-label {

					  label {
						font-weight:normal;
						position:absolute;
						pointer-events:none;
						left:10px;
						top:14px;
						transition:0.2s ease all;
						-moz-transition:0.2s ease all;
						-webkit-transition:0.2s ease all;
							.note{
								display: none;
							}
						}
						.floating-input.invalid ~ label>span.note{
							display: inline-block;
						}
					}
					.floating-input.invalid ~ label{
						color: #AB023E;
						display: block;
					}
					.floating-input.invalid ~ label .floating-label{
						background-color: turquoise;
					}
					.floating-input ~ .highlight {
						display: none;
					}
					.floating-input.invalid ~ .highlight {
						display: inline-block;
						color: #fff;
						font-size: 14px;
						position: absolute;
						top: -22px;
					}
				  .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
					top:4px;
					font-size:14px;
				  }

				  .floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
					width:50%;
				  }

				  *, *:before, *:after {
					  -webkit-box-sizing: border-box;
					  -moz-box-sizing: border-box;
					  box-sizing: border-box;
				  }

				  .floating-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
					// -webkit-animation:inputHighlighter 0.3s ease;
					// -moz-animation:inputHighlighter 0.3s ease;
					// animation:inputHighlighter 0.3s ease;
				  }

				  /* animation */
				  @-webkit-keyframes inputHighlighter {
					  from { background:#5264AE; }
					to 	{ width:0; background:transparent; }
				  }
				  @-moz-keyframes inputHighlighter {
					  from { background:#5264AE; }
					to 	{ width:0; background:transparent; }
				  }
				  @keyframes inputHighlighter {
					  from { background:#5264AE; }
					to 	{ width:0; background:transparent; }
				  }


			}

		}
	}
	.overlay-thanku-layout{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 11;
		.c-col-m-6 {
			padding: 0;

		}
		.thanku-page {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100vh;
			padding-left: 120px;
			h3 {
				font-size: 200px;
				line-height: 160px;
				font-family: 'TIMMONSNY-Regular';
				font-weight: normal;
				color: #FF80FF;
				margin: 0;
				margin-bottom: 11px;
			}
			p{
				font-size: 22px;
				line-height: 30px;
				margin: 0;
				margin-bottom: 14px;

				span {
					color: #FF80FF;
					font-weight: bold;
				}
			}
			.margin-bottom{
				margin-bottom: 48px !important;
			}
			.md-right-auto {
				margin-right: auto;
			}
		}
	}

	.overlay-tech-tips{
		/*display: flex;
		flex-direction: row;*/
		padding: $spacing-xs;

		.overlay-tech-tips-container{
			display: flex;
			flex-flow: column wrap;
			justify-content: start;

			.video, .further-reading,
			.tech-tips, .social-share-wrapper{
				width: 100%;
			}

			@media screen and (max-width: 767px) {
				.video{order: 1;}
				.further-reading{order: 3;}
				.tech-tips{order: 2;}
				.social-share-wrapper{order: 4;}
			}

			@media screen and (min-width: 768px) {
				height: 90vh;

				.video, .further-reading{
					width: 60%;
				}

				.tech-tips, .social-share-wrapper{
					width: 30%;
				}
			}

			@media screen and (min-width: 768px) and (max-width: 1023px){
				height: 100vh !important;
			}
		}

		.video{
			iframe{
				width: 100%;
		    height: 215px;
		    position: absolute;
		    left: 0;
			}
		}
		.video-transcript{
			justify-content: flex-end;
			display: flex;
			p{
				background-color: getColor(bt-dark-indigo);
				border-radius: 25px;
				padding: 5px;
				margin-top: 18px;
				a {
					color: getColor(white);
					text-decoration: none;
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}

		.further-reading{
			h2{
				font-size: 22px;
			}

			h3, p{
				font-size: 14px;
			}

			h3{
				margin: 0;
			}

			p{
				margin: $spacing-xs 0 $spacing-xs 0;
			}

			&-item {
				text-decoration: none;

				p {
					color: getColor(white);
				}

				&:hover,
				&:focus,
				&:active {
					h3 {
						text-decoration: underline;
					}
					span{
						background-image: url("/static/images/overlay/external-link-Key-fill.svg");
				  }
				}
			}

			.c-grid-row {
				margin-bottom: 20px;
			}

			.left-border{
				padding: $spacing-xs 0 $spacing-s 0;
				border-top: 2px dotted getColor(bt-pink);
				border-left:none;
			}
		}

		.tech-tips{
			margin-top: 245px;
    	padding-top: 35px;
			h2, h3{
				margin-top: $spacing;
				margin-bottom: 0;
			}
			p{
				margin-top: 0;
				margin-bottom: 0;
			}
			h2, p, a{
				font-size: 18px;
				line-height: 24px;
			}
			a {
				color: getColor(white);
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			ul {
				list-style-type: none;
				padding: 0;
				margin: $spacing-s 0 0 0;
			}
			li {
				background: url('/static/images/overlay/list-icon.png') no-repeat left top;
				padding-left: 25px;
				padding-bottom: 15px;

				span.tip-text{
					font-size: 16px;
					line-height: 20px;
					position: relative;
					left: 0;
					top:-5px;
				}
			}
		}

		.social-share-wrapper{
			p{
				font-weight: bold;
			}
			a:first-child{
				margin-right: 10px;
			}
		}

	}

	.overlay-player-profile{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 11;

		.c-col-m-6{
			padding: 0;
		}

		.player-image-main{
			width: 100%;
			@media screen and(min-width: 767px) {
				height: 100vh;
				width: auto;
			}
		}

		.left-container, .right-container{
			position: relative;
		}

		.country-badge{
			position: absolute;
			top: 100%;
			left: 85%;
			transform: translate(-50%, -50%);
			max-width: 53px;
		}
		.player-info {
			position: absolute;
			/*top: 92%;
			left: 19%;
			transform: translate(-50%, -50%);*/
			padding-left: 30px;
			margin-top: -110px;
			text-align: left;
		}

		.staff-info {
			position: absolute;
			padding-left: 30px;
			margin-top: -125px;
			text-align: left;
		}

		span.role{
			font-family: 'TIMMONSNY-Regular';
			font-weight: normal;
			font-size: 40px;
			line-height: 32px;
			color: getColor(bt-pink);
		}

		h2.player-number{
			font-family: 'TIMMONSNY-Regular';
			font-weight: normal;
			font-size: 180px;
			line-height: 140px;
			color: getColor(bt-pink);
			margin: 0
		}

		.player-detail{
			max-width: 600px;
			margin: 0;
			padding: 30px;

			h2, h3{
				font-family: 'TIMMONSNY-Regular';
				font-weight: normal;
				color: getColor(hope-yellow);
				margin: 0;
				text-transform: uppercase;
			}

			h3{
				font-size: 40px;
				line-height: 40px;
				margin-bottom: 0.2em;
				margin-top: 1em;
			}

			h2{
				font-size: 78px;
				line-height: 66px;
				margin-bottom: 0.2em;
			}

			hr{
				border: none;
				border-bottom: 4px dotted getColor(hope-yellow);
				text-align: left;
				width: 100%;
				margin-left: 0;
			}

			p.intro{
				font-size: 18px;
				line-height: 24px;
			}

			span{
				font-family: 'Btcurve w bd';
			}

			p.quote{
				font-size: 20px;
				line-height: 28px;
				margin-top: -45px;
				position: relative;
				i.start-quote{
					font-family: 'TIMMONSNY-Regular';
					font-weight: normal;
					font-size: 100px;
					line-height: 80px;
					position: relative;
					left: -15px;
					top: 51px;
					margin-right: $spacing-xs;
				}

				i.end-quote{
					font-family: 'TIMMONSNY-Regular';
			    font-weight: normal;
			    font-size: 100px;
			    line-height: 0px;
			    position: absolute;
				bottom: -20px;
				margin-left: -10px;
				}

			}

			.slider-btn {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 18px;
				color: #FF80FF;
				margin-top: 10px;
				margin-right: 0px;

				a{
					height: 40px;
					img.default-icon{
						display: inline-block;
					}
					img.hover-icon, img.icon-disabled{
						display: none;
					}
					&:hover img.default-icon{
						display: none;
					}
					&:hover img.hover-icon{
						display: inline-block;
					}
					&.arrow-disabled:hover img.hover-icon{
						display: none;
					}
					&.arrow-disabled  img.default-icon{
						display: none;
					}
					&.arrow-disabled img.icon-disabled{
						display: inline-block;
					}
				}
				span{
					text-align: center;
				}
			}

		}

		h2.message-text{
			position: absolute;
			top: -22%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-family: 'TIMMONSNY-Regular';
			font-weight: normal;
			font-size: 77px;
			line-height: 63px;
			color: getColor(white);
			text-align: center;
		}

	}
	.overlay-team-profile{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 11;

		.team-profile-wrapper{
			position: relative;
			@media screen and (min-width: 768px) {
				position: static;
			}
		}

		.team-profile-image{
			width: 100%;
			// min-height: 50vh;
			@media screen and (min-width: 1024px) {
				height: 100vh;
			}
		}

		.team-profile-text{
			position: absolute;
			top: 85%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-family: 'TIMMONSNY-Regular';
			font-weight: normal;
			font-size: 160px;
			line-height: 128px;
			color: getColor(white);
			text-align: center;

			@media screen and (min-width: 768px) and (max-width: 1023px) {
				top: 45%;
				font-size: 205px;
				line-height: 170px;
			}

			@media screen and (min-width: 1024px) {
				top: 55%;
				font-size: 273px;
				line-height: 218px;
			}
		}
	}

	.video {
	}

	.video iframe {
	  width: 100%;
	  height: auto;
	}

	.c-col-sm{
		width: 100%;
	}

	[class*="c-col-"] {
	  float: left;
	  padding: $spacing-xs;
	}

	.c-grid-row{
		margin-bottom: $spacing-xs;
	}

	.c-grid-row::after {
	  content: "";
	  clear: both;
	  display: table;
	}

	.c-col-8{
		width: 100%;
	}

	.c-col-4{
		width: 100%;
	}

	.icon {
	  width: 48px;
	  height: 48px;
	}

	.icon-newtab {
		background-size: contain;
		background-repeat: no-repeat;
		// background-image: url("/static/images/overlay/icon-newtab-small.png");
		background-image: url("/static/images/overlay/external-link-Key.svg");
		width: 13px;
		height: 13px;
		display: inline-block;
	}

	.ml-auto{
		margin-left: auto;
	}
	@media screen and (min-width: 992px) {
		.signup-form .signup-form-wrapper .form-layout {
			>div{
				&:first-child{
					padding-right: 240px;
				}
			}
		}
	}

	.m-show{
		display: block;
	}
	.m-hide{
		display: none;
	}

	@media screen and (min-width: 768px) {

		.overlay-nav{
			padding: $spacing-m;

			.overlay-hu-logo-white{
				img{
					height: auto;
					width: 106px;
				}
			}
		}

		.overlay-tech-tips{
			padding: $spacing-m;

			.tech-tips{
				padding-top: 0;
				margin-top: 0;
				h2, h3{
					margin-top: $spacing-xl;
				}

				h2, p{
					font-size: 22px;
					line-height: 30px;
				}
			}

			.video{
				iframe{
					position: relative;
					width: 100% !important;
	    		height: 430px;
				}
			}

			/*.video iframe {
				width: 100% !important;
			}*/

			.left-border{
				padding: 0 $spacing-xs 0 $spacing-xs !important;
				border-left: 2px dotted getColor(bt-pink) !important;
				border-top:none !important;
			}
		}

		.overlay-player-profile{

			/*.player-image-main{
				max-height: 100vh;
			}*/
			.left-container, .right-container{
				position: static;
			}

			.country-badge{
				position: absolute;
				top: 55%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.player-info, .staff-info{
				top: 32%;
				left: 50%;
				padding: 0;
				margin: 0;
				transform: translate(-50%, -50%);
			}

			h2.player-number{
				font-size: 300px;
				line-height: 230px;
			}

			.player-detail{
				display: flex;
				flex-direction: column;
				height: 100vh;
				padding-left: 120px;
				padding-right: 50px;
				padding-top: 150px;
				transition: all 280ms ease-in-out;
				h2{
					font-size: 96px;
					line-height: 68px;
				}

				p.intro{
					font-size: 22px;
					line-height: 30px;
				}

				p.quote{
					font-size: 24px;
					line-height: 32px;
					padding-right: 20px;
					i{
						margin-right: $spacing-m;
					}
				}
				hr{
					width: 95%;
				}
				.slider-btn{
					margin-right: 20px;
				}
			}

			h2.message-text{
				top: 50%;
				font-size: 145px;
				line-height: 125px;
				margin: 0
			}

		}

		.video {
		}

		.video iframe {
			width: 760px;
			height: 430px;
		}

		[class*="c-col-"] {
		  float: left;
		  padding: $spacing-m;
		}

		.c-col-m-8{
			width: 66.66%;
		}

		.c-col-m-6{
				width: 50%;
		}

		.c-col-m-4{
			width: 33.33%;
		}

		.d-show{
			display: block;
		}
		.d-hide{
			display: none;
		}

		.signup-form {
			.signup-form-wrapper .form-layout {
				display: flex;
				flex-wrap: wrap;
			}

		}
		.overlay-hero-video{
			display: flex;
			align-items: center;
			.video-transcript p{
				margin-top: 18px;
			}
			.hero-video-content {
				margin-top: -40px;
				padding-right: 32px;
			}
			.video-iframe-wrapper {
				height: 428px;
			}
			.social-share-wrapper{
				a{
					width: 40px;
					height: 40px;
				}
			}
		}

	}
	
	@media screen and (max-width: 992px) {
		.signup-form {
			.signup-form-wrapper .form-layout {
				>div{
					&:first-child{
						padding-right:64px;
					}
				}
				.form-footer{
					margin-top: 83px;
				}
			}
		}
		.overlay-thanku-layout{
			.thanku-image-main{
				height: 100vh;
				width: auto;
			}
			.thanku-page {
				padding-left: 64px;
				padding-right: 40px;
				 h3 {
					font-size: 140px;
					line-height: 112px;
				}
			}
		}
	}

	@media screen and (max-width: 767px) {
		.signup-form {
			.signup-form-wrapper{
				>p{
					font-size: 18px;
					line-height: 24px;
				}
				.form-layout {
					>div{
						&:first-child{
							padding-right:0px;
							margin-bottom: 50px;
						}
					}
					.checkbox-container {
						font-size: 16px;
						line-height: 20px;
						margin-bottom: 40px;
					}
					.form-footer{
						margin-top: 20px;
						display: block;
						margin-bottom: 53px;

						> div .cta {
							display: block;
							width: 100%;
							margin-top: 30px;
						}

						a {
							font-size: 18px;
							line-height: 21px;
						}
					}
				}
			}

		}
		.overlay-thanku-layout{
			.thanku-image-main{
				height: auto;
				width: 100%;
			}
			.thanku-page {
				padding: 50px 32px 32px 32px;
				height: auto;
				 h3 {
					font-size: 70px;
					line-height: 56px;
				}
				p {
					font-size: 18px;
					line-height: 23px;
				}
			}
		}

		.overlay-tech-tips{
			.video-transcript{
				position: absolute;
				margin-top: 220px;
				right: 30px;
			}
		}

		.overlay-hero-video{
			margin-right: 0;
			margin-left: 0;
			> div:first-child{
				padding: 0;
			}
			> div:last-child{
				padding: 0 38px;
			}
			.video-iframe-wrapper{
				// position: absolute;
		    width: 100%;
		    padding-bottom: 0;
		    height: 215px;
		    left: 0;
			}
			.video-transcript{
				margin-top: 14px;
				padding-right: 38px;
			}
			.hero-video-content h2{
				font-size: 18px;
				line-height: 24px;
			}
			.social-share-wrapper{
				margin-top: 50px;
			}
		}

		.player-detail{

		}
	}

	@media screen and (min-width: 1025) {
		overflow-y: hidden;
	}

	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.c-col-tb{
			width: 100% !important;
		}

		.tb-show{
			display: block !important;
		}
		.tb-hide{
			display: none !important;
		}
	}
}
//Test new image container as background image
.overlay-player-profile, .team-profile-wrapper{
	.image-container {
        img {
            width: 100%;
        }
        @media screen and(min-width: 768px){
            img {
                opacity: 0;
            }
			//background-image: var(--tablet-image) !important;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top center;
        }

		@media screen and(min-width: 1025px){
			//background-image: var(--desktop-image) !important;
		}
    }
}

body {
	&.overlay-open {
		overflow: hidden;
	}
	.bold{
		font-weight: bold;
	}
}

.is-fixed,
.overlay-open:not(.is-fixed) {
	.overlay-hu-logo-white {
		visibility: hidden !important;
	}
}

@media screen and (min-width: 768px) and (max-width: 1366px) {

	.overlay {

		.overlay-player-profile {
			h2.message-text{
				top: 50%;
				font-size: 95px;
				line-height: 95px;
				margin: 0
			}

			h2.player-number {
				font-size: 170px;
				line-height: 150px;
			}
			span.role {
				font-size: 40px;
				line-height: 12px;
			}
		}
	}
	
}
