.home-nations {
    h2 {
        margin-bottom: 40px;
    }

    .content-wrapper {
        @media screen and(min-width: 768px){
            display: flex;
            column-gap: 5%;
        }

        .nations-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 20%;
            grid-row-gap: 40px;
            padding: 0 0 8% 0;

            @media screen and (min-width: 768px) {
                grid-column-gap: 10%;
                padding: 0 4% 0 0;
            }

            @media screen and (min-width: 992px) {
                grid-column-gap: 15%;
            }
            a{
                text-decoration: none;
                @include ie11() {
                    &:nth-child(1){
                        -ms-grid-row: 1;
                        -ms-grid-column:1;
                    }

                    &:nth-child(2){
                        -ms-grid-row: 1;
                        -ms-grid-column:2;
                    }

                    &:nth-child(3){
                        -ms-grid-row: 2;
                        -ms-grid-column:1;
                    }

                    &:nth-child(4){
                        -ms-grid-row: 2;
                        -ms-grid-column:2;
                    }
                }
                .nation-item {
                    text-align: center;
                    .img-wrapper {
                        min-height: 116px;
                        height: 116px;
                        margin: 0 auto;
                        margin-bottom: 7px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    span {
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 400;

                    }
                }
                &:hover{
                  span {
                    text-decoration: underline;
                  }
                }
            }

        }

        > div:nth-child(1) {
            border-bottom: 2px dashed #E1F935;
            margin-bottom: 24px;

            @media screen and(min-width: 768px){
                width: 30%;
                border-bottom: none;
                border-right: 2px dashed #E1F935;
                margin-bottom: 0;
            }
        }

        > div:nth-child(2) {
            display: flex;
            justify-content: space-around;
            margin-bottom: 40px;

            @media screen and(min-width: 768px){
                width: 15%;
                flex-direction: column;
                margin-bottom: 0;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            p {
                margin-bottom: 0;
                flex: 0 0 58%;
                @media screen and(min-width: 768px){
                    flex: 0;
                }
            }

            img {
                width: 20%;
                @media screen and(min-width: 768px){
                    width: 80%;
                }
            }
        }

        > div:nth-child(3) {
            @media screen and(min-width: 768px){
                width: 50%;
            }
        }
    }

    .text-wrapper {
        position: relative;

        // @media screen and(min-width: 768px){
        //     padding-bottom: 50px;
        // }

        p {
            font-size: 22px;
            line-height: 30px;
            margin-top: 0;
        }
        a {
            width: 90%;
            @media screen and(min-width: 992px){
                width: 65%;
                margin-top: 35px;
                // position: absolute;
                // bottom: 0;
            }
            @media screen and(max-width: 768px){
                width: 100%;
                margin-top: 35px;
                // position: absolute;
                // bottom: 0;
            }
          &:hover{
            border: 2px solid getColor('bt-indigo');
          }
        }
    }
}
