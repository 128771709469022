/*Import Swiper*/
@import "../../node_modules/swiper/swiper.scss";
@import "../../node_modules/swiper/components/pagination/pagination.scss";

// Core - do not edit those files!
@import 'core/normalize.scss';
@import "core/colors";
@import "core/spacers";
@import 'core/sm.scss';
@import 'core/md.scss';
@import 'core/lg.scss';
@import 'core/xl.scss';

@import 'utils/_mixins';

/* and component over-rides  */
@import 'components/header.scss';
@import 'components/hero-section.scss';
@import 'components/join-us.scss';
@import 'components/card-carousel.scss';
@import 'components/sponsors.scss';
@import 'components/tech-tips.scss';
@import 'components/home-nations.scss';
@import 'components/footer.scss';
@import 'components/overlay.scss';
@import 'components/consent-overlay.scss';
@import 'components/overlay-two-column.scss';
@import 'components/full-team-shot.scss';
@import 'components/component-two-column.scss';
@import 'components/introduction.scss';



/* pages  styles */
@import 'pages/cookie-policy.scss';
@import 'pages/terms.scss';
@import 'pages/error.scss';
@import 'pages/article.scss';

