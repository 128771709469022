.consent-overlay {
    display: none;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(85, 20, 180, .9);
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    &.show {
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        opacity: 1;
        width: 100%;
        height: 100%;
        z-index: 9;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-start;

        .ovelay-content {
            max-width: 80%;
            margin: 0 auto;
            
            margin-top: 10%;
            position: relative;
            padding: 0;
            
            .text-wrapper {
                text-align: center;
                h2{
                    font-size: 36px;
                    line-height: 42px;
                    @media screen and(max-width: 767px) {
                        font-size: 26px;
                    line-height: 30px;
                    }
                }
                p{
                    font-size: 22px;
                    line-height: 30px;
                    @media screen and(max-width: 767px) {
                        font-size: 18px;
                    line-height: 24px;
                    }
                }
            }

            .cta-wrapper {
                display: flex;
                flex-direction: column;
            }

            @media screen and(min-width: 768px) {
                max-width: 50%;
                //padding: 20px;
                .cta-wrapper {
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                }
            }
        }
    }
}

body {
    &.home-preload {
        position: relative;
        overflow: hidden;
        .home {
            position: fixed;
        }
        .home-united-logo {
            display: none;
        }
        .gradient-default {
            margin-top: 50px;
            opacity: 0.4;
        }        
    }
}