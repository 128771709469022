/* Nav Bar */

nav {
    position: absolute;
    background: transparent;
    width: 100%;
    z-index: 2;
    height: 64px;
}

.home-united-logo-black,
.home-united-logo-white {
    position: absolute;
    left: calc(50% - 33px);
    transition: all linear 0.5s;
    display: none;

    img {
        max-width: 76px;
    } 
    
    @media screen and (min-width: 768px) {
        left: calc(50% - 53px);
        img {
            max-width: 106px;
        }  
    }
}

.home {
    margin-top: 8px;
    img {
        max-width: 48px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 16px;
        img {
            max-width: 64px;
        }   
    }
}

