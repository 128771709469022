.article {
    .home-united-logo-white {
        display: block;
        top: 20px;
    }

    .max-width-container {
        @media screen and(max-width: 767px){
            margin: 0 20px;
        }
    }

    .openseadragon-container {
        > div:not(.openseadragon-canvas) {
            margin-top: 24px !important;
            margin-left: 24px !important;
            img {
                max-width: 24px;
                max-height: 24px;
                margin-right: 12px !important;
            }

            @media screen and (min-width: 768px) {
                margin-top: 32px !important;
                margin-left: 32px !important;

                img {
                    max-width: 40px;
                    max-height: 40px;
                }
            }
        }
    }

    .article-hero {
        padding-top: 128px;

        @media screen and (min-width: 768px) {
            padding-top: 160px;
        }
    }

    .article-heading {
        font-size: 30px;
        line-height: 36px;

        @media screen and (min-width: 992px) {
            font-size: 46px;
            line-height: 54px;
        }
    }

    .article-bodycopy {
        font-size: 18px;
        line-height: 24px;

        @media screen and (min-width: 992px) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .article-body {
        padding-bottom: 30px;

        @media screen and (min-width: 768px) {
            padding-bottom: 46px;
        }
    }

    .article-content {
        margin: 0 20px;
        position: relative;
        margin-top: 30px;

        @media screen and (min-width: 768px) { 
            margin-right: 40px;
            margin-left: 40px;
        }

        @media screen and (min-width: 992px) { 
            max-width: 760px;
            margin: 0 auto;
        }
        
        .social-share-wrapper {
            display: inline-flex;
            align-items: center;
            margin-top: 26px;

            .share-text {
                margin-left: 21px;
            }

        }

        .icon {
            width: 48px;
            height: 48px;
        }

        .bt-techtips-callout {
            margin-top: 52px;

            @media screen and(min-width: 992px){
                margin-top: 73px;
            }
        }
    }

    .media-container {
        height: 220px;
        background: #141417;
        overflow: hidden;

        @media screen and (min-width: 768px) {
            max-width: 1200px;
            margin: 0 auto;
            height: 450px;
        }

        @media screen and (min-width: 992px) {
            height: 654px;
        }
    }

    .media-wrapper {
        position: relative;
    }

    .pink-strip {
        display: none;

        @media screen and (min-width: 992px) {
            display: block;
            height: 56px;
            position: absolute;
            width: 100%;
            top: 20%;
            background-image: url("/static/images/HBH-Graphic.svg");
            background-repeat: repeat-x;
            background-position: center;
            z-index: -1;
        }
    }

    .pink-link {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        font-family: 'Btcurve w bd';
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 16px;

        &::before {
            content: " ";
            width: 28px;
            height: 28px;
            background-image: url("/static/images/arrow-left.png");
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 10px;
        }

        &:hover {
            text-decoration: underline;

            &::before {
                background-image: url("/static/images/hover-arrow-left.svg");
            }
        }
    }
}