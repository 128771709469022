.hero-section {
    position: relative;
    padding: 0;
    width: 100%;
    min-height: 400px;
    height: auto;
    //max-height: calc(100vh - 100px);
    transition: all 1s linear;

    .max-container {
        overflow: hidden;
    }
    
    .preload-image {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #5514B4;
        opacity: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        transition: opacity 1s linear;
        transition-delay: 1s;
        clear: both;
    }

    .section {
        width: 100%;
        position: relative;
        z-index: 1;

        .content-section {
            position: relative;
            width: 100%;
            opacity: 0;
            transition: left 1s linear 1.5s, right 1s linear 1.5s, opacity 1s linear 2s;
            height: 315px;
            overflow: hidden;

            img {
                position: absolute;
            }
        }

        &.left-section {
            .content-section {
                left: -100%;
            }
        }

        &.right-section {
            .content-section {
                right: -100%;
            }
        }
    }

    .hero-logo-wrapper  {
        display: flex;
        align-items: center;
        position: absolute;
        top: 44%;
        justify-content: center;
        width: 100%;

        .hero-logo-container {
            position: relative;
            &:before {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                width: 76px;
                height: 76px;
            }
            @media screen and(min-width: 767px) {
                width: 160px;
                height: 160px;
            }
        }

        .hero-logo {
            opacity: 0;
            transition: opacity 1s linear 0.8s;
            position: absolute;
            top: 0;
            z-index: 2;
        }

        .primary-heading {
            transition: opacity 1s linear;
            opacity: 0;
            margin: 0;
            z-index: 2;

            @media screen and(max-width: 767px) {
                font-size: 53px;
                line-height: 80%;
            }

            &.right-text {
                margin-left: 12px;
                transition-delay: 1.7s;
            }

            &.left-text {
                margin-right: 12px;
                transition-delay: 1.5s;
            }
        }
    }

    .hero-cta-wrapper {
        position: absolute;
        margin: 0 auto;
        left:0;
        right: 0;
        bottom: 5%;
        text-align: center;
        z-index: 1;
        opacity: 0;
        transition: opacity 1s linear;
        transition-delay: 3s;

        .cta {
            margin: 0;
        }
    }

    .consent-set & {
        &::after,
        .hero-logo,
        .primary-heading,
        .hero-cta-wrapper {
            opacity: 1;

        }

        .left-section {
            .content-section {
                left: 0;
                opacity: 1;
            }
            
        }

        .right-section {
            .content-section {
                right: 0;
                opacity: 1;
            }
        }
    }

    //Animation for cross fade
    @keyframes cf4FadeInOut {
        0% {
          opacity:1;
        }
        17% {
          opacity:1;
        }
        25% {
          opacity:0;
        }
        92% {
          opacity:0;
        }
        100% {
          opacity:1;
        }
      }

     .start-animation {
        .left-section picture,
        .right-section picture {
            animation-name: cf4FadeInOut;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: 24s;
        }
     }

     .left-section picture{
        &:nth-of-type(1) {
            animation-delay: 18s;
        }
        &:nth-of-type(2) {
            animation-delay: 12s;
        }
        &:nth-of-type(3) {
            animation-delay: 6s;
        }
        &:nth-of-type(4) {
            animation-delay: 0;
        }
     }

     .right-section picture {
        &:nth-of-type(1) {
            animation-delay: 21s;
        }
        &:nth-of-type(2) {
            animation-delay: 15s;
        }
        &:nth-of-type(3) {
            animation-delay: 9s;
        }
        &:nth-of-type(4) {
            animation-delay: 3s;
        }
     }


    //all overidse before loading stay here
    .home-preload & {
        height: 100vh;
    }
}

.is-fixed,
.overlay-open:not(.is-fixed) {
    
    .hero-logo {
        margin-top: 10px;
        position: fixed !important;
        z-index: 12  !important;
        display: block;
        width: 76px;
        height: 76px;
        left: calc(50% - 38px);
        
        @media screen and(min-width: 767px) {
            width: 106px;
            height: 106px;
            left: calc(50% - 53px);
        }
    }
}



@media screen and (min-width: 768px) {
    .hero-section {
        
        .max-container {
            max-width: 1440px;
            margin: 0 auto;
            position: relative;
        }

        .section {
            top: 0;
            width: 50%;
            height: 100%;
            z-index: 1;
            background: transparent;

            .content-section {
                width: 100%;
                height: 100%;
                height: 720px;

                img {
                    max-width: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &.left-section {
                left: 0;
                float: left;

                img {
                    right: 0;
                }
            }

            &.right-section {
                right: 0;
                float: right;
            }
        }

        .hero-logo-wrapper  {
            top: 33%;
        }

        .hero-cta-wrapper {
            bottom: 15%;
        }
    }
}
@media screen and (min-width: 992px) {
    .hero-section  .section .content-section img {
            // max-width: none;
            max-width: 100%;
        }
    
}
.hero-section-callout {
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 1;
    opacity: 0;
    margin-top: -18px;
    transition: all 1s linear;
    transition-delay: 3s;

    .content-wrapper {
        margin: 0 30px;
        display: inline-block;
        // border-bottom: 2px dashed #E1F935;
        padding: 0 20px;
        p{
            font-size: 18px;
            line-height: 24px;
            @media screen and (min-width: 768px) {
                font-size: 22px;
            line-height: 30px;
            }
        }
        @media screen and (min-width: 768px) {
            margin: 0 auto;
            padding: 0 40px 10px 40px;
        }
    }

    .consent-set & {
        opacity: 1;
    }
}
