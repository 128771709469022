@media screen and (min-width: 768px) {
    .max-width-container {
        margin-right: 40px;
        margin-left: 40px;
    }
    .home {
        margin-top: 16px;
    }
    .home img {
        max-width: 64px;
    }
    .home-united-logo img {
        max-width: 160px;
    }
    .home-united-logo {
        margin-left: -80px;
        margin-top: 16px;
    }
    
    .step-grid-wrapper .step .cta-wrapper p {
        margin-top: 4.56em;
        margin-bottom: 1.25em;
        line-height: 1.875;
    }
    /*changes*/
    section {
        padding: 3.75em 0;
        /*48px*/
    }
    .md-inline.cta,
    .md-inline.cta:focus,
    .md-inline.cta:hover,
    .md-inline.cta:active {
        margin-left: auto;
        margin-right: auto;
    }
    .center-content-block {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        justify-content: center;
        align-items: center;
        text-align: left;
        column-gap: 60px;
        img {
            margin: 0 auto;
        }
    }

    .feed-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .card-wrapper {
            width: 43%;
            margin: 20px;

            .card {
                height: 100%;
            }
        }
    }
    .card {
        &.is-split-card {
            >.desc {
                min-height: 77%;
            }
        }
    }
    .next-match-wrapper {
        .schedule-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .schedule {
            width: 50%;
            padding: 0.5em 2em;
            text-align: right;
        }
        .has-separator {
            border-top: none;
            border-left: dashed 2px #000000;
            text-align: left;
        }
    }
    .club-manifesto {
        .bg-pattern {
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
    .marquee-block {
        p {
            font-size: 13.125em;
            img {
                max-width: 160px;
            }
        }
    }
    .social-section {
        .primary-heading {
            margin: 0.428em 0 0;
        }
        .d-flex {
            display: flex;
            align-items: flex-end;
        }
        .ml-auto {
            margin-left: auto;
            display: flex;
            align-items: center;
        }
        .row {
            padding: 0;
            .col-lg-3 {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: 20px;
                padding-left: 20px;
                margin: 0;
                /*&:first-child {
                    padding-left: 0px;
                }
                &:last-child {
                    padding-right: 0;
                }*/
            }
        }
    }

    .gradient-default {
        padding-bottom: 8em;
    }

    .heading-small {
      p {
          font-size: 1.38em;
      }
      >* {
          margin-right: 25%;
      }
      .bt-tech-logo{
        font-size: 1.5em
      }
    }

    .card {
      &.upcoming {
        border-radius: 20px;
        .desc{
          padding-top: 30px;
          padding-bottom: 50px;
        }
      }
    }

    .feed-container{
      .cta-container{
        p{
          font-size: 22px;
        }
      }
      .primary-heading{
        margin-bottom: 40px;
      }
    }
}

@media all and (device-width: 1024px) {
    .max-width-container {
        margin-right: 40px !important;
        margin-left: 40px !important;
    }
}
