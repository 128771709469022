@media screen and (min-width: 992px) {
  .max-width-container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 40px;
  }
  
  .feed-wrapper {
    margin-bottom: 2.5em;
    
    .card-wrapper {
      margin: 0;
      width: 31%;
    }
  }
  .card {
    &.is-split-card {
      > .desc {
        min-height: 75.5%;
      }
    }
  }

  .next-match {
    .center-content-block {
      flex-direction: row;
    }
    .next-match-wrapper > p {
      margin-top: 0;
    }
  }
  .club-manifesto {
    .primary-heading {
      font-size: 7.375em;
    }
  }
  .social-section{
    .row {
      padding: 0;
      .col-lg-3 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;

        /*&:first-child{
          padding-left: 0px;
        }
        &:last-child{
          padding-right: 0;
        }*/
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .social-section{
    .row {
      padding: 0;
      .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0;

        &:first-child{
          padding-left: 0px;
        }
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
}
