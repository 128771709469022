.component-two-column {
    >div {
        margin: 0 auto;
    }
    .d-flex {
        display: block;
    }
    .flex-fill {
        flex: 1;
        display: flex;
        align-items: center;
        align-self: flex-end;

        &.self-center {
            align-self: center;
        }

        img.ml-auto {
            margin-left: auto;
            display: flex;
        }
    }
    .flex-column {
        padding: 1em 2.5em 2.31em 2.5em;
    }

    .body-copy {
        font-size: 18px;
        line-height: 24px;
    }

    @media screen and(min-width: 768px){
        >div {
            display: flex;
        }
        .flex-column {
            width: auto;
        }
    }

    @media screen and(min-width: 992px){
        .flex-column {
            padding-left: 7em;
        }
    }

    @media screen and(min-width: 1024px){
        .body-copy {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .small-text {
        font-size: 16px;
        line-height: 23px;
    }

    &.share-pledge {
        .body-copy {
            margin: 16px 0 20px 0;
        }

        @media screen and(min-width: 1024px){
            .body-copy {
                margin: 30px 0;
            }
        }
    }
}