//PAGE SPECIFIC COMPONENT OVERRIDES
.terms {
    nav {
        position: relative;
    }

    .home {
        margin-top: 8px;
        img {
            max-width: 48px;
        }
    }

    .home-united-logo-black {
        display: block;
        top: 20px;
    }
}

.terms-container {
    padding-top: 4.8em;
    min-height: 80vh;
    overflow: hidden;

    .table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    h1 {
        font-size: 30px;
        line-height: 35px;
        @media screen and (min-width: 768px) {
            font-size: 46px;
            line-height: 54px;
        }
    }

    h2 {
        font-size: 26px;
        line-height: 30px;
        @media screen and (min-width: 768px) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    h3 {
        font-size: 22px;
        line-height: 25px;
        @media screen and (min-width: 768px) {
            font-size: 26px;
            line-height: 42px;
        }

    }

    p, ul {
        font-size: 22px;
        line-height: 30px;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        td {
            padding: 10px 10px;
        }
    }
}
